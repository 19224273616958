@use 'scss/abstracts' as *;
@use 'scss/fonts' as *;

.success-toaster {
    font-family: Poppins;
    color: $color-naf-primary-blue;
}

.failure-toaster {
    font-family: Poppins;
    color: $color-alert;
}
