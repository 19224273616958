@use 'scss/abstracts' as *;
@use 'scss/fonts' as *;

/*---------------------------------------------------------------------------
  Referral Success
---------------------------------------------------------------------------*/

.referralSuccess {
    font-size: toRem(12px);
    line-height: toLineHeightUnit(12px, 16px);
    text-align: left;
    font-family: $poppins;
    margin: 0;

    &__name {
        padding: 0 toRem(4px);
        font-weight: 500;
    }
}
