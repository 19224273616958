/*---------------------------------------------------------------------------
    Colors
---------------------------------------------------------------------------*/
// Naf Colors
$color-naf-primary-blue: #091644;
$color-naf-secondary-blue: #3b6ccf;
$color-naf-tertiary-blue: #889bd8;

$color-naf-dark: #1d1f2a;
$color-naf-light: #dde2ec;
$color-naf-light-2: #e4e9f2;

// Black - White
$color-black: #000000;
$color-dark-charcoal: #333333;
$color-matte-black: #1c1c1c;
$color-light-grey: #f5f5f5;
$color-light-grey-2: #d9d9d9;
$color-light-grey-3: #cccccc;
$color-white: #ffffff;
$color-catskill-white: #f6f8fa;
$color-steel-gray: #1d1f2a99;
$color-tuatara: #323130;
$color-cod-gray: #1d1d1d;

// Gray
$color-gray: #8f8f8f;
$color-dust-gray: #999999;
$color-dove-gray: #707070;
$color-medium-gray: #666666;
$color-silver-chalice: #b1b1b1;
$color-mercury: #e2e2e2;
$color-athens-gray: #eef0f5;
$color-ghost-white: #f8f9ff;
$color-faded-gray: #c9c9c9;
$color-aqua-haze: #f2f5f8;
$color-logan-gray: #a3a6b8;
$color-shuttle-gray: #676a7a;

// Blue
$color-light-blue: #2a5c9e;
$color-lighter-blue: #f9feff;
$color-blue: #3e5d85;
$color-link-blue: #0000ee;
$color-blumine: #1e4d73;
$color-astronaut-blue: #3464c7;
$color-stratos: #00000040;
$color-shakespeare: #3f93cf;
$color-sky: #4689c8;
$color-zumthor: #ebf5ff;
$color-perano: #aad1f1;
$color-alice-blue: #f2f9ff;
$color-curious-blue: #a7c7e4;
$color-primary-button-hover-blue: #3464c7;
$color-facebook-blue: #1877f2;
$color-mystic: #dde2ecbf;
$color-east-bay: #455e8c;
$color-denim: #1976d2;

// Purple
$color-secondary-purple: #3c52cf;
$color-tertiary-purple: #889bd8;

// Green
$color-la-palma: #179917;
$color-zigzy-green: #0caf0c;

// Red
$color-alert: #c70731;
$color-danger: #d62516;

// Yellow
$color-warning: #edac1b;
$color-goldstar: #fabf6f;

// Border
$color-border-1: #c4dbed;
$color-border-2: #dfebf5;
$color-border-3: #d1d4e3;

/*---------------------------------------------------------------------------
    Global Colors
---------------------------------------------------------------------------*/

// Form
$color-error: $color-alert;
$color-error-border: $color-alert;

// Company Colors
$color-linkedin-blue: #0077b5;
$color-google-gray: $color-light-grey-3;
