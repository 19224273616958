@use '../abstracts/variables' as *;

$cdn-roboto: $cdn-fonts + 'roboto/';

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 300;

	src: url($cdn-roboto + 'Roboto-Light.ttf');
	src: url($cdn-roboto + 'Roboto-Light.ttf') format('truetype');
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;

	src: url($cdn-roboto + 'Roboto-Regular.ttf');
	src: url($cdn-roboto + 'Roboto-Regular.ttf') format('truetype');
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;

	src: url($cdn-roboto + 'Roboto-Medium.ttf');
	src: url($cdn-roboto + 'Roboto-Medium.ttf') format('truetype');
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;

	src: url($cdn-roboto + 'Roboto-Bold.ttf');
	src: url($cdn-roboto + 'Roboto-Bold.ttf') format('truetype');
}
