/*---------------------------------------------------------------------------
	CDN
---------------------------------------------------------------------------*/
$cdn: 'https://assets.naf-connect.com/';
$cdn-fonts: 'https://assets.naf-connect.com/assets/fonts/';

/*---------------------------------------------------------------------------
	Font Related Variables
---------------------------------------------------------------------------*/
// Fonts
$base-font: Roboto, sans-serif;
$poppins: Poppins;

// Global Variables
$base-size: 16px;
