@use '../abstracts/variables' as *;

$cdn-poppins: $cdn-fonts + 'poppins/';

@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 300;

	src: url($cdn-poppins + 'Poppins-Light.ttf');
	src: url($cdn-poppins + 'Poppins-Light.ttf') format('truetype');
}

@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 400;

	src: url($cdn-poppins + 'Poppins-Regular.ttf');
	src: url($cdn-poppins + 'Poppins-Regular.ttf') format('truetype');
}

@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;

	src: url($cdn-poppins + 'Poppins-Medium.ttf');
	src: url($cdn-poppins + 'Poppins-Medium.ttf') format('truetype');
}

@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;

	src: url($cdn-poppins + 'Poppins-SemiBold.ttf');
	src: url($cdn-poppins + 'Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 700;

	src: url($cdn-poppins + 'Poppins-Bold.ttf');
	src: url($cdn-poppins + 'Poppins-Bold.ttf') format('truetype');
}
