@use 'scss/abstracts' as *;
@use 'scss/components' as *;
@use 'scss/fonts' as *;

// Toaster CSS values
:root {
    --toastify-icon-color-success: #091644;
    --toastify-icon-color-error: #c70731;
    --toastify-color-progress-success: #091644;
    --toastify-color-progress-error: #c70731;
}
