.co-branding-container {
    font-size: 16px;
    padding: 60px;
    background-color: #ffffff;
    font: normal normal medium 24px/30px Poppins;
}

.section-title {
    margin-left: 30px;
}

.update-date {
    margin-top: 30px;
}
.co-branding-title {
    display: flex;
    justify-content: center;
    margin-top: 35px;
}
.print {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
