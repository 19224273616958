@use 'scss/abstracts' as *;
@use 'scss/fonts' as *;

/*---------------------------------------------------------------------------
  Indicator
---------------------------------------------------------------------------*/
.indicator {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
