@use 'scss/abstracts' as *;

body {
    background-color: $color-catskill-white;
    color: $color-naf-dark;
    font-family: $base-font;
    font-size: $base-size;
    font-weight: 400;
    min-height: 100vh;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}
