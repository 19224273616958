.header-legal_header {
    display: flex;
    align-items: center;
    background-color: #091644;
    justify-content: center;
    padding: 25px;
}

.header-legal_header__logo {
    width: 169px;
    height: 51.8px;
}

.terms-of-use_terms {
    color: #333333;
    line-height: calc(toRem(24px) / toRem(16px));
    padding: 20px;
}

.terms-of-use_terms__heading {
    color: #013560;
    font-family: Roboto, sans-serif;
    font-size: 32px;
    opacity: 1;
}

.terms-of-use_terms__line {
    opacity: 0.2;
    margin-bottom: 45px;
}

.terms-of-use_terms__title {
    font-family: Roboto, sans-serif;
    text-align: left;
    margin-bottom: 0;
}

.terms-of-use_terms__text {
    margin: 0 0 16px 0;
    padding: 0;
}

.terms-of-use_terms__text--bold {
    font-weight: bold;
}

.terms-of-use_terms__link {
    display: inline-block;
    color: #3b6ccf;
    text-decoration: underline;
    padding: 0 4px;
}

.terms-of-use_terms__list {
    list-style: none;
    margin: 0 0 16px 0;
    padding: 0;
}

.terms-of-use_terms__list--secondary > *:before {
    content: '•';
    padding-right: 8px;
}

.footer-legal_footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-legal_footer__terms-conditions {
    display: flex;
    align-items: center;
    background: #1d1f2a 0 0 no-repeat padding-box;
    color: #ffffff;
    flex-direction: row;
    font: Roboto, sans-serif;
    font-size: 12px;
    justify-content: center;
    opacity: 1;
    padding: 10px;
}

.footer-legal_footer__link {
    color: #ffffff;
    font-size: 12px;
    text-decoration: underline;
    padding: 12px;
}

@media only screen and (max-width: 576px) {
    .footer-legal_footer__terms-conditions {
        font-size: 10px;
    }

    .footer-legal_footer__link {
        font-size: 10px;
    }
}

.footer-legal_footer__text {
    padding: 5px;
}
